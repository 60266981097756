// import { onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { ROUTES } from '../../../routes/app.constants';
// import {getVerifyUser} from '../../services/api/endpoints/contentful';
// import { auth, googleProvider } from '../../services/firebase';
import { reportError } from '../../utils/reportError';
import axiosBackendApi from '../../services/api/axiosBackendInstance';
import * as React from "react";
interface State {
  user: AdminPanelUser | null;
  isLoading: boolean;
  registerLoading: boolean;
  errorMessage: string;
  signIn: (email: string, password: string) => Promise<void>;
  verifyRegistration: (userInfo : object) => Promise<void>;
  otpErrorMessage: string;
  otpLoading: boolean;
  showOtp: boolean;
  otpSendSuccess: boolean;
  showLoginVerify: boolean;
  firstNameError: string;
  lastNameError: string;
  passwordError: string;
  emailError: string;
  signUp: (userInfo : object) => Promise<void>;
  logout: () => Promise<void>;
  resendCode: (userInfo : object) => Promise<void>;
}

export const AdminPanelContext = createContext<State | undefined>(undefined);

interface AdminPanelContextProviderProps {
  children: ReactNode;
}

interface AdminPanelUser {
  id: string;
  name: string;
  email: string;
  token: string;
  role: [];
  permissions: [];
}

export const AdminPanelContextProvider = ({ children }: AdminPanelContextProviderProps) => {
  const history = useHistory();
  const [user, setUser] = useState<AdminPanelUser | null>(() => {
    const localData = localStorage.getItem('user');
    return localData ? JSON.parse(localData) : [];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const signIn = async (email: string, password: string) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axiosBackendApi.post('admin/user/auth/login', {
        email: email,
        password: password,
      });
      // await getVerifyUser(user.email || '');
      if (typeof response.data.data.user_details !== 'undefined') {
        try {
          // await getVerifyUser(user.email || '');
          const user = {
            id: response.data.data.user_details.id,
            name: response.data.data.user_details.full_name,
            email: response.data.data.user_details.email,
            token: response.data.data.access_token,
            role: response.data.data.user_details.role,
            permissions: response.data.data.user_details.permissions,
          };
          setUser(user);
          console.log(user.token);
          localStorage.setItem('user', JSON.stringify(user));

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
          axios.defaults.headers['Authorization'] = 'Bearer ' + user.token;


          axiosBackendApi.interceptors.request.use((config) => {
            if(config.headers){
              config.headers['Authorization'] =
                'Bearer ' + user.token;
              config.params = {
                ...config.params,
              };
            }

            if(config.headers.common){
              config.headers.common['Authorization'] =
                'Bearer ' + user.token;
              config.params = {
                ...config.params,
              };
            }


            return config;
          });
        } catch (err) {
          setUser(null);
          await logout();
        }
        return setIsLoading(false);
      }
      // setUser(null);
      // setIsLoading(false);

      // history.push(ROUTES.home);
      setErrorMessage('');
    } catch (err) {
      reportError(err);
      if(err.response.data.code == 403){
        setShowLoginVerify(true);
      }else {
        setErrorMessage(err.response?.data?.message || '')
      }
    }
    setIsLoading(false);
  };

  const logout = async () => {
    setIsLoading(true);
    await axiosBackendApi.post('admin/user/auth/logout');
    axios.defaults.headers.common['Authorization'] = '';
    setUser(null);
    localStorage.removeItem('user');
    setIsLoading(false);
    history.push(ROUTES.login);
    window.location.reload()
  };

  const signUp = async (userPersonalInfo : object) => {
    setRegisterLoading(true);
    setFirstNameError("");
    setFirstNameError("");
    setFirstNameError("");
    setFirstNameError("");
    try {
       await axiosBackendApi.post('admin/user/auth/register', userPersonalInfo).then((response) => {
        setRegisterLoading(false);
        setShowOtp(true)
         // setErrorMessage('');
      })
    } catch (error) {
      setFirstNameError(error.response?.data?.errors?.first_name )
      setLastNameError(error.response?.data?.errors?.last_name )
      setPasswordError(error.response?.data?.errors?.password )
      setEmailError(error.response?.data?.errors?.email )
    }
    setRegisterLoading(false);
  };

  const [showLoginVerify, setShowLoginVerify] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpSendSuccess, setOtpSendSuccess] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState('');
  const [otpLoading, setOtpLoading] = React.useState(false);
  const verifyRegistration = async (otpData : object) => {
    setOtpLoading(true);
    setOtpErrorMessage('');
    try {
       await axiosBackendApi.post('admin/user/auth/otp/verify',otpData ).then((response) => {
         setOtpLoading(false);
         setOtpErrorMessage('');
         setShowOtp(false)
         history.push(ROUTES.login);
         setShowLoginVerify(false)
      })
    } catch (err) {
      setOtpErrorMessage(err.response?.data?.message || '');
    }
    setOtpLoading(false);
  };

  const resendCode = async (email) => {
    setOtpErrorMessage('');
    setOtpSendSuccess(false);
    try {
      await axiosBackendApi.post('admin/user/auth/otp/resend', email).then((response) => {
        setOtpErrorMessage('')
        setOtpSendSuccess(true)
      })
    } catch (error) {
      setOtpErrorMessage(error.response?.data?.message || '')
      setOtpSendSuccess(false)
    }
  }

  const value = { user, isLoading, errorMessage, signIn, logout, signUp,resendCode, registerLoading,verifyRegistration, otpErrorMessage, otpLoading, showOtp, otpSendSuccess, showLoginVerify, firstNameError, lastNameError, passwordError, emailError};

  return <AdminPanelContext.Provider value={value}>{children}</AdminPanelContext.Provider>;
};

export const useAdminPanelContext = () => {
  const context = useContext(AdminPanelContext);

  if (!context) {
    throw new Error('AdminPanelContext used outside of Provider!');
  }

  return context;
};
