// @ts-nocheck
import React, { useEffect, useState, } from 'react';
import { Answer } from './answer.component';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { newLog, newRegenerateLog, patchLog } from '../../store/actions/sessionsActions';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import chatMessages from '../chat/components/chat.messages';

export const ChatAnswer = ({ question, answers, liked, logId, ...props }) => {
  const { id } = useParams() as { id: string };

  const dispatch = useDispatch();
  const [controller, setController] = useState(new AbortController());
  const [streaming, setStreaming] = useState(false);
  const [signal, setSignal] = useState(controller.signal);
  const [current, setCurrent] = useState(0);
  const SEARCH_URL = process.env.REACT_APP_WEB_API;
  const [logLoader, setLoagLoader] = useState(false)
  const localData = localStorage.getItem('user');
  const userData = localData ? JSON.parse(localData) : null;
  const token = userData?.token;
  const nextAnswer = () => {
    if (current < answers.length - 1) setCurrent(current + 1);
  };
  const prevAnswer = () => {
    if (current > 0) setCurrent(current - 1);
  };
  const CHAT_URL = process.env.REACT_APP_CHAT_API;

  const generate = (answer) => {
    if (answer.model == chatMessages.chatLLM.id) {
      fetchData('/chat_regenerate', answer.model);
    } else if (answer.model == chatMessages.chatRAGOnline.id) {
      fetchData('/rag_online_regenerate', answer.model);
    } else if (answer.model == chatMessages.chatRAGOffline.id) {
      fetchData('/rag_mongodb_regenerate', answer.model);
    } else if (answer.model == chatMessages.chatBrief7b.id) {
      fetchData('/llama_summarize_7b', answer.model);
    } else if (answer.model == chatMessages.chatBrief13b.id) {
      fetchData('/llama_summarize_13b', answer.model);
    } else if (answer.model == chatMessages.chatFiles.id) {
      fetchData('/rag_pdf_regenerate', answer.model);
    }
  };
  const fetchData = async (chatUrl, model) => {
    let payload = {};
    const localData = localStorage.getItem('user');
    const userData = JSON.parse(localData);
    payload = { user_id: userData.id, query: question, session_id: id };
    let stopChunks = false;
    const url = CHAT_URL + chatUrl;
    // setStreaming(true);
    try {
      const myResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
        signal: signal,
      });

      if (!myResponse.ok) {
        const errorData = await myResponse.json();
        // handleReceiveMessage(errorData.errors.error_message, 'error');
      }

      const reader = myResponse.body.getReader();
      // setLoading(false);
      let chunks = '';
      reader.read().then(function processText({ done, value }) {
        // setShowStopResponse(true);
        let chunk = new TextDecoder('utf-8').decode(value);
        if (done) {
          storeLog(id, chunks, model);
          // setShowStopResponse(false);
          // setShowIcons(true);
          // setAnswer(chunks);
          // setInputDisabled(false);
          // setChatting(false);
          // startSession(chunks);
          return;
        }
        if (stopChunks) {
          storeLog(id, chunks, model);
          // // console.log('stop');
          // setShowIcons(true);
          // setAnswer(chunks);
          // setChatting(false);
          //
          // // setShowStopResponse(false);
          // setInputDisabled(false);
          // startSession(chunks);
          return;
        }
        if (chunk === '### SEARCHING' || chunk === '### PROCESSING') {
          chunks = chunk;
        } else {
          if (chunks === '### PROCESSING') chunks = chunk;
          else {
            chunks += chunk;
            chunks = chunks.replace(/### SEARCHING/g, '');
            // remove '### PROCESSING' from the chunks
            chunks = chunks.replace(/### PROCESSING/g, '');
          }
        }

        dispatch(newRegenerateLog({ chunks, logId, answers, model } as any));
        setCurrent(answers.length ? answers.length : 0);

        // setCurrent(updatedAnswers.length - 1);

        // handleReceiveMessage(chunks, '');
        return reader.read().then(processText);
      });
    } catch (error) {
    } finally {
    }
  };

  const storeLog = async (session_id, answer, model) => {

    setLoagLoader(true);
    let data = {
      question: question,
      answer: answer,
      session_id: session_id,
      model,
    };
    axiosBackendApi.post(SEARCH_URL + '/user/session/log/' + logId + '/regenerate_answer', data).then((response) => {
      if (response.status == 200) {
        if (response.data.session.answers) {
          dispatch(patchLog({ logId: logId, answers: response.data.session.answers }));
        }
      }
      setLoagLoader(false)
    });
  };

  const hideAnswer = !(answers[current] && Object.keys(answers[current])?.length > 0);

  return (
    <>
      {!hideAnswer && (
        <Answer
          key={answers[current]?.id}
          answer={answers[current]}
          liked={liked}
          userName={answers[current]?.model ? answers[current]?.model : 'User name'}
          timestamp={answers[current]?.created_at}
          message={answers[current]?.answer}
          current={current}
          isQuestion={false}
          handelNextAnswer={nextAnswer}
          handelPrevAnswer={prevAnswer}
          logId={logId}
          total={answers?.length}
          handelRegenerate={() => generate(answers[current])}
          logLoader={logLoader}
          setLoagLoader={setLoagLoader}
        />
      )}
    </>
  );
};
