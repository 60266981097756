// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import EditIcon from '@mui/icons-material/Edit';
import dots from '../../images/Dots.svg';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { ThumbsDownOutlined, ThumbsDownSolid } from '../../images/svg/thumbsDown';
import { ThumbsUpOutlined, ThumbsUpSolid } from '../../images/svg/thumbsUp';
import Close from '../../images/svg/close';
import Reload from '../../images/svg/reload';
import Copy from '../../images/svg/copy';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import chatMessages from '../chat/components/chat.messages';
import { useIntl } from 'react-intl';
import LanguageIcon from '@mui/icons-material/Language';
import ChatIcon from '@mui/icons-material/Chat';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TranslateIcon from '../../images/svg/translate';
import Loader from './loader';
import SourcesComponent from './resourcesCards';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import arabic from 'dayjs/locale/ar';
import { useLanguage } from '../../modules/lang/lang.context';
import useRerenderInterval from '../../shared/hooks/useRerenderInterval';
import { useDispatch } from 'react-redux';
import { patchAnswer } from '../../store/actions/sessionsActions';
const UPDATE_TIME_INTERVAL = 10000;
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const Answer = ({
  answer,
  liked: initialLiked,
  userName,
  timestamp,
  message,
  current,
  isQuestion,
  handelNextAnswer,
  handelPrevAnswer,
  logId,
  total,
  handelRegenerate,
  logLoader,
  setLoagLoader
}) => {
  const { locale } = useLanguage();
  const dispatch = useDispatch();
  const intl = useIntl();
  locale === 'ar' ? dayjs.locale(arabic) : dayjs.locale('en');
  const [loader, setLoader] = useState(false);
  const [loadingRegenerate, setLoadingRegenerate] = useState(false);
  const [logDisplay, setLogdisplay] = useState(false)
  const [translate, setTranslate] = useState(false);
  const SEARCH_URL = process.env.REACT_APP_WEB_API;
  const CHAT_URL = process.env.REACT_APP_CHAT_API;
  const [liked, setLiked] = useState(answer.liked);
  const [messageTranslated, setMessageTranslated] = useState('');

  const chatMessagesend = (content) => {
    const words = content.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += 50) {
      lines.push(words.slice(i, i + 50).join(' '));
    }

    return lines.join('\n');
  };
  const handelClick = () => {
    setLoadingRegenerate(true);
    handelRegenerate();
    setLogdisplay(true);
    current++;
    total++;

    // setTimeout(() => {
    //   setLoadingRegenerate(false);
    // }, 2000);

  };
  // const handelClick = () => {
  //     handelRegenerate();
  //     current++;
  //     total++;
  // }
  const nextAnswer = () => {
    handelNextAnswer();
  };
  const prevAnswer = () => {
    handelPrevAnswer();
  };

  // Rerender every UPDATE_TIME_INTERVAL milliseconds to update the relative time
  useRerenderInterval(UPDATE_TIME_INTERVAL);

  useEffect(() => {
    if (translate) {
      let data = {
        query: message,
      };
      // setLoader(true);

      axiosBackendApi.post(CHAT_URL + '/translate', data).then((response) => {
        setLoader(false);
        setMessageTranslated(response.data.translate);
      });
    }
  }, [translate]);

  const copyMessage = async (message: string) => {
    if (translate) {
      message = messageTranslated;
    }
    try {
      // await navigator.clipboard.writeText(message);
      var textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = message.replace(/(<([^>]+)>)/gi, '');
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setOpen(true);
    } catch (e) { }
  };

  const handleLikeClick = () => {
    const newLikeState = liked !== 1 ? 1 : 0; // Toggle like state
    setLiked(newLikeState);
    updateLikeStatus(newLikeState);
  };

  const handleDislikeClick = () => {
    const newLikeState = liked !== 2 ? 2 : 0; // Toggle dislike state
    setLiked(newLikeState);
    updateLikeStatus(newLikeState);
  };

  const updateLikeStatus = (like) => {
    const data = {
      like: like,
      _method: 'patch',
    };
    axiosBackendApi
      .post(`${SEARCH_URL}/user/session/log/like/${answer.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          console.log('Updated like status:', response.data);
          dispatch(patchAnswer({ logId, answerId: answer.id, liked: like }));
        }
      })
      .catch((error) => {
        console.error('Error updating like status:', error);
      });
  };

  // TODO: Remove this component not used
  const likeIcon = () => {
    return !liked ? (
      <ThumbUpOutlinedIcon fontSize={'small'} sx={{ color: 'rgba(135,142,165,0.75)' }} />
    ) : (
      <ThumbUpIcon fontSize={'small'} sx={{ color: 'rgba(135,142,165,0.75)' }} />
    );
  };

  // TODO: Remove this component not used
  const disLikeIcon = () => {
    return liked ? (
      <ThumbDownOffAltOutlinedIcon fontSize={'small'} sx={{ color: 'rgba(135,142,165,0.75)' }} />
    ) : (
      <ThumbDownAltIcon fontSize={'small'} sx={{ color: 'rgba(135,142,165,0.75)' }} />
    );
  };

  const options = [
    {
      value: 'chatLLM',
      selected: true,
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatLLM)}</span>,
    },
    {
      value: 'chatRAGOnline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOnline)}</span>,
    },
    {
      value: 'chatRAGOffline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOffline)}</span>,
    },
    {
      value: 'chatBrief7b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief7b)}</span>,
    },
    {
      value: 'chatBrief13b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief13b)}</span>,
    },
    {
      value: 'chatFiles',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatFiles)}</span>,
    },
  ];
  const getLabelByValue = (value) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : null;
  };
  const sources = [];
  let match;
  const regex = /domain:(?<domain>[^&]+)&icon_url:(?<icon_url>[^&]+)&title:(?<title>.*?)&url:(?<url>[^\s]+)/gs;

  while ((match = regex.exec(message)) !== null) {
    sources.push({
      domain: match.groups.domain,
      icon_url: match.groups.icon_url,
      title: match.groups.title.trim(),
      url: match.groups.url,
    });
  }

// Remove "Sources:" section from text
  message = message.replace(/Sources:\s*([\s\S]*)$/, '').trim();

  function SyntaxHighlightedCode(props) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current && props.className?.includes('lang-') && window.hljs) {
        window.hljs.highlightElement(ref.current);
        ref.current.removeAttribute('data-highlighted');
      }
    }, [props.className, props.children]);
    return <code {...props} ref={ref} />;
  }

  // while ((match = regex.exec(message)) !== null) {
  //   sources.push({
  //     domain: match.groups.domain,
  //     icon_url: match.groups.icon_url,
  //     title: match.groups.title,
  //     url: match.groups.url,
  //   });
  // }

  // Remove "Sources:" section from text
  // message = message.replace(/Sources:\s*([\s\S]*)$/, '').trim();

  return (
    <div className="chatAnswer">
      {message === '### SEARCHING' ? (
        <div className="loading" style={{ width: '100px' }}>
          <div className="loading__typing"></div>
        </div>

      ) : message === '### PROCESSING' ? (
        <div className="loading" style={{ width: '100px' }}>
          <div className="loading__typing"></div>
        </div>

      ) : (
        <>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              borderRadius: 2,
              marginBottom: 3,
              boxShadow: 1,
              border: 0.5,
              borderColor: 'rgba(80,80,80,0.19)',
            }}
          >
            <Box className="messageHeader" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              {isQuestion && (
                <Avatar alt={userName} sx={{ marginRight: 1 }}>
                  {userName.charAt(0)}
                </Avatar>
              )}
              <Typography variant="subtitle2"> {getLabelByValue(userName)} </Typography>
              <Typography
                variant="caption"
                sx={{ color: localStorage.getItem('localTheme') === 'light' ? '#383838' : '#414141' }}
              >
                {dayjs(timestamp).fromNow()}
              </Typography>
            </Box>
            <Typography variant="body2" className="messageContent">
              {loadingRegenerate ? (
                <div className="loading" style={{ width: '100px' }}>
                  <div className="loading__typing"></div>
                </div>
              ) : translate ? (
                <p dir={'rtl'}>
                  <Markdown
                    children={chatMessagesend(messageTranslated)}
                    options={{ overrides: { code: SyntaxHighlightedCode } }}
                  />
                </p>
              ) : (

                <Markdown
                  children={chatMessagesend(message)}
                  options={{ overrides: { code: SyntaxHighlightedCode } }}
                />
              )}
              <div>
                <SourcesComponent sources={sources} />
              </div>
            </Typography>
            {logId != undefined && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {
                    logLoader ? (
                      <div className="loading" style={{ width: '100px' }}>
                        <div className="loading__typing"></div>
                      </div>
                    ) : (
                      logDisplay ? (
                        <div></div>
                      )
                        : (
                          <>
                            {!translate && (
                              <IconButton className="btn__icon" onClick={handelClick}>
                                <Reload />
                              </IconButton>
                            )}
                            <IconButton className="btn__icon" onClick={() => copyMessage(message)}>
                              <Copy />
                            </IconButton>
                            <IconButton className="btn__icon" onClick={handleLikeClick} title="Good Response">
                              {liked === 1 ? <ThumbsUpSolid /> : <ThumbsUpOutlined />}
                            </IconButton>
                            <IconButton className="btn__icon" onClick={handleDislikeClick} title="Bad Response">
                              {liked === 2 ? <ThumbsDownSolid /> : <ThumbsDownOutlined />}
                            </IconButton>
                            {!translate ? (
                              <Tooltip
                                title="Translation"
                                placement="top"
                                onClick={() =>
                                  setTranslate(() => {
                                    return !translate;
                                  })
                                }
                              >
                                <IconButton className="btn__icon">
                                  <TranslateIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Close Translation" placement="top" onClick={() => setTranslate(false)}>
                                <IconButton className="btn__icon">
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            )}
                            <KeyboardArrowLeft
                              className="cursor-pointer"
                              onClick={prevAnswer}
                              fontSize={'small'}
                              sx={{ color: 'rgba(135,142,165,0.75)', strokeWidth: 0.5, stroke: 'rgba(135,142,165,0.56)' }}
                            />
                            <Box className="cursor-pointer" sx={{ color: 'rgba(135,142,165,0.75)' }}>
                              {current + 1}
                            </Box>{' '}
                            <Box sx={{ color: 'rgba(135,142,165,0.75)' }}>/</Box>{' '}
                            <Box sx={{ color: 'rgba(135,142,165,0.75)' }}>{total}</Box>
                            <KeyboardArrowRight
                              className="cursor-pointer"
                              onClick={nextAnswer}
                              fontSize={'small'}
                              sx={{ color: 'rgba(135,142,165,0.75)', strokeWidth: 0.5, stroke: 'rgba(135,142,165,0.56)' }}
                            />
                          </>
                        )
                    )
                  }

                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
};
