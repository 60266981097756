// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Copy from '../../images/svg/copy';
import reload from '../../images/Reload.svg';
import share from '../../images/Share 3.svg';
import bookmark from '../../images/Bookmark.svg';
import dots from '../../images/Dots.svg';
import { useSelector, useDispatch } from 'react-redux';
import { clearSession, fetchSessionById } from '../../store/actions/sessionsActions';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { ChatAnswer } from './chat-answer.component';
import { Input, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import Loader from './loader';  // Assuming Loader is a component that displays a loading spinner or animation
const ChatMessage = ({ userName, timestamp, message, isQuestion }) => {
  function SyntaxHighlightedCode(props) {
    const ref = React.useRef(null);

    React.useEffect(() => {
      if (ref.current && props.className?.includes('lang-') && window.hljs) {
        window.hljs.highlightElement(ref.current);
        ref.current.removeAttribute('data-highlighted');
      }
    }, [props.className, props.children]);

    return <code {...props} ref={ref} />;
  }

  const chatMessagesend = (content) => content.toString();

  const copyMessage = async (message) => {
    try {
      var textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = message.replace(/(<([^>]+)>)/gi, '');
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (e) { }
  };

  const [edit, setEdit] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);
  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = () => {
    setEdit(false);
  };
  return (
    <Box
      className="chatResponse"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isQuestion
          ? localStorage.getItem('localTheme') === 'light'
            ? '#ffffff'
            : 'rgba(4,40,36,0.32)'
          : localStorage.getItem('localTheme') === 'light'
            ? '#F4F6F8'
            : 'rgba(4,40,36,0.73)',
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <Typography variant="subtitle2" sx={{ marginRight: 1, fontWeight: 'bold' }}>
          {userName}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: localStorage.getItem('localTheme') === 'light' ? '#383838' : '#414141' }}
        >
          {timestamp}
        </Typography>
      </Box>

      {edit ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Input
            value={editedMessage}
            onChange={(e) => setEditedMessage(e.target.value)}
            fullWidth
            multiline
          />
          <Button sx={{ width: "30px" }} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <Typography variant="body2">
          <Markdown options={{ overrides: { code: SyntaxHighlightedCode } }} children={message} />
        </Typography>
      )}

      {/* <Typography variant="body2">
        <Markdown options={{ overrides: { code: SyntaxHighlightedCode } }} children={message} />
      </Typography> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isQuestion ? (
            <>
              <IconButton className="btn__icon" onClick={() => copyMessage(message)}>
                <Copy />
              </IconButton>
              {/*<IconButton className="btn__icon" onClick={handleEdit}>*/}
              {/*  <EditIcon width={5} />*/}
              {/*</IconButton>*/}
            </>
          ) : (
            <>
              <IconButton>
                <img src={reload} alt="Reload" />
              </IconButton>
              <IconButton>
                <img src={copy} alt="Copy" />
              </IconButton>
              <IconButton>
                <img src={share} alt="Share" />
              </IconButton>
              <IconButton>
                <img src={bookmark} alt="Bookmark" />
              </IconButton>
              <IconButton>
                <img src={dots} alt="Dots" />
              </IconButton>
              <KeyboardArrowLeft className="cursor-pointer" />
              <span>{1}</span>/<span>{2}</span>
              <KeyboardArrowRight className="cursor-pointer" />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default function ChatInterface() {
  const session = useSelector((state) => state.sessions.session);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const user = localStorage.getItem('user');

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        try {
          await dispatch(fetchSessionById(id));
        } catch (error) {
          console.error('Failed to fetch session:', error);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (id) {
        dispatch(clearSession());
      }
    };
  }, [dispatch, id]);

  useEffect(() => {
    scrollToBottom();
  }, [session.logs]);

  return (
    <div className={'chat-container'} ref={chatContainerRef}>
      {isLoading ? (
        <Box className="loading" sx={{ height: '70vh' }}>
          <div className="loading__spinner"></div>
        </Box>
      ) : (
        session?.logs?.map((log, i) => (
          <React.Fragment key={i}>
            <ChatMessage userName={JSON.parse(user).name} timestamp={''} message={log.question} isQuestion={true} />
            {log.answers?.length > 0 ? (
              <ChatAnswer question={log.question} answers={log.answers} logId={log.id} />
            ) : (
              <div className="loading" style={{ width: '100px' }}>
                <div className="loading__typing"></div>
              </div>
            )}
          </React.Fragment>
        ))
      )}
    </div>
  );
}

function truncateString(str, maxLength = 500) {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
}
