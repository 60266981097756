// @ts-nocheck

import { useEffect, useReducer, useState } from 'react';

import { TitleTagSize } from '../../shared/components/titleTag/titleTag.types';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Container, TitleTag } from './newsLetter.styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as React from 'react';
import axios from 'axios';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { CreateNewsLetter } from './create.component';
import { EditNewsLetter } from './edit.component';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NoDataUrl from '../../images/No-data-pana.png';
import SearchUrl from '../../images/search.gif';
import SearchLoadingImg from '../../images/loading.gif';
import Snackbar from '@mui/material/Snackbar';
import Pagination from '@mui/material/Pagination';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Switch from '@mui/material/Switch';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import { alpha, display, styled } from '@mui/system';
import './newsLetter.component.css';

const SEARCH_URL = process.env.REACT_APP_WEB_API;

// TODO: MOVE TO SHARED
const AppSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    color: '#fff !important',
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    color: '#085950 !important',
  },
  '& .MuiTouchRipple-root': {
    display: 'block !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#085950',
    '&:hover': {
      backgroundColor: alpha('#085950', 0.2),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#085950',
  },
}));

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const style = {
  position: 'absolute',
  top: '30px',
  left: '30px',
  // transform: 'translate(0%, -10%)',
  width: 'calc(100% - 60px)',
  // bgcolor: 'background.paper',
  bgcolor: '#333',
  border: '2px solid rgba(0,0,0, 0.5)',
  boxShadow: 24,
  p: 2,
  borderRadius: '8px',
};

export const NewsLetter = ({ locale, messages }) => {
  const intl = useIntl();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const openDeleteModal = (index: number) => {
    setNewsLetterId(newsLetters[index].id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => setDeleteModal(false);

  const [createModal, setCreateModal] = React.useState(false);
  const openCreateModal = () => setCreateModal(true);
  const closeCreateModal = () => setCreateModal(false);

  function handleDataFromChild() {
    closeCreateModal();
    getNewsLetter();
  }

  const [newsLetterId, setNewsLetterId] = React.useState<number>();
  const [newsLetterInfo, setNewsLetterInfo] = React.useState<object>({});
  const [editModal, setEditModal] = React.useState(false);
  const openEditModal = (index: number) => {
    setEditModal(true);
    setNewsLetterId(newsLetters[index].id);
    setNewsLetterInfo(newsLetters[index]);
  };
  const closeEditModal = () => setEditModal(false);

  function closeEditModalFromChild() {
    console.log('fff');
    closeEditModal();
    getNewsLetter(currentPage);
  }

  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [newsLetters, setNewsLetters] = React.useState<object[]>([]);
  const getNewsLetter = (value) => {
    setLoading(true);
    const user = localStorage.getItem('user');
    const token = JSON.parse(user).token;
    const user_id = JSON.parse(user).id;
    axios
      .get(SEARCH_URL + '/newsletters', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setNewsLetters(response.data.data.reports);
          // const pages_count = Math.ceil( response.data.data.total / response.data.data.count)
          setCountPage(response.data.data.last_page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [count, setCountPage] = React.useState(1);
  const getPaginatedData = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    getNewsLetter(value);
  };
  const deleteNewsLetter = () => {
    setLoadingDelete(true);
    axios
      .delete(SEARCH_URL + '/newsletters/' + newsLetterId)
      .then((response) => {
        setLoadingDelete(false);
        handleClickSuccess();
        closeDeleteModal();
        getNewsLetter(currentPage);
      })
      .catch((error) => {
        setLoadingDelete(false);
        handleClickFailed();
      });
  };

  // success alert
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  // Failed alert
  const [openFailed, setOpenFailed] = React.useState(false);
  const handleClickFailed = () => {
    setOpenFailed(true);
  };
  const handleCloseFailed = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailed(false);
  };
  //
  const handleClickEdit = () => {
    closeEditModal();
    getNewsLetter(currentPage);
  };
  const handleClickCreate = () => {
    closeCreateModal();
    getNewsLetter(currentPage);
  };
  const history = useHistory();

  const showArtilces = (id) => {
    history.push('/Newsletters/' + id);
  };

  const handleSwitchChange = (newsletterId, status) => async (event) => {
    const newStatus = !status;
    try {
      const response = await axiosBackendApi.post(`${SEARCH_URL}/newsletters-agent/${newsletterId}`, {
        status: newStatus ? 1 : 0,
        __method: 'PUT',
      });

      const newsletterIndex = newsLetters.findIndex((newsLetter) => {
        return newsLetter.id == newsletterId;
      });

      setNewsLetters((newsLetters) =>
        newsLetters.map((newsLetter, i) =>
          i === newsletterIndex ? { ...newsLetter, status: response.data.data.data.status } : newsLetter
        )
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('fetchCharts', 'false');
    getNewsLetter(currentPage);
  }, []);

  return (
    <>
      {locale == 'en' ? (
        <Helmet
          title={intl.formatMessage({
            id: 'Newsletter',
            defaultMessage: 'Newsletter',
            description: 'Newsletter',
          })}
        />
      ) : (
        <Helmet
          title={intl.formatMessage({
            id: 'Newsletter',
            defaultMessage: 'نشرة الأخبار',
            description: 'نشرة الأخبار',
          })}
        />
      )}
      <Box sx={{ marginTop: 10 }} className="newsLettersPage">
        <div className="w-100 flex flex-col gap-4">
          <div className={'search-top'}>
            {/*<div className="d-flex flex-gap-regular align-items-center">*/}
            {/*  <Box className="page-title" sx={{color:localStorage.getItem('localThem') === 'light' ? "#000 !important" : "#ccc !important" }}>{messages.newsLetter}</Box>*/}
            {/*</div>*/}
            {/*<Link href={ROUTES.CreateNewsLetter}>*/}
            <Button
              variant="contained"
              className="create-newsletter-btn"
              startIcon={<AddIcon />}
              onClick={() => openCreateModal()}
            >
              {messages.CreateNewsletterAgent}
            </Button>
            {/*</Link>*/}
          </div>
          <div className="position-relative news-letter page">
            {loading ? (
              <div className="data-loading">
                <img src={SearchLoadingImg} className="loading-img" />
              </div>
            ) : (
              <>
                {newsLetters.length ? (
                  <>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ borderTop: 0 }}>
                          <TableRow>
                            <TableCell align="left">{messages.Domains}</TableCell>
                            <TableCell align="left">{messages.SubDomains}</TableCell>
                            <TableCell align="left">{messages.Frequently}</TableCell>
                            <TableCell align="left"> Send Email</TableCell>
                            <TableCell align="center">{messages.Actions}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newsLetters.map((newsLetter, index) => (
                            <TableRow
                              key={newsLetter.newsLetter}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                <div className="flex items-center gap-1">
                                  {newsLetter.domains.map((value, index) => (
                                    <span>
                                      {index < 2 ? (
                                        <Chip
                                          key={value}
                                          label={value}
                                          sx={{
                                            marginLeft: index == 0 ? 0 : '',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {index == 2 ? (
                                        // <small className="ml-1"> and {newsLetter.domains?.length - 5} more</small>
                                        <div class="badge badge-light flex h-full ml-1 rounded-full">
                                          +<span>{newsLetter.domains?.length - 2}</span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <div className="flex items-center gap-1">
                                  {newsLetter.sub_domains?.map((value, index) => (
                                    <span>
                                      {index < 2 ? (
                                        <Chip
                                          key={value}
                                          label={value}
                                          sx={{
                                            marginLeft: index == 0 ? 0 : '',
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {index == 2 ? (
                                        // <small className="ml-1"> and {newsLetter.sub_domains?.length - 2} more</small>
                                        <div class="badge badge-light flex h-full ml-1 rounded-full">
                                          +<span>{newsLetter.sub_domains?.length - 2}</span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                <Box
                                  sx={{
                                    backgroundColor:
                                      newsLetter.frequently === 'Daily'
                                        ? ' rgb(6, 91, 80,0.06)!important'
                                        : newsLetter.frequently === 'Monthly'
                                        ? 'rgb(6, 91, 80,0.16) !important'
                                        : 'rgb(8, 89, 80,0.65) !important',
                                    color: !(newsLetter.frequently === 'Daily' || newsLetter.frequently === 'Monthly')
                                      ? 'white !important'
                                      : 'black',
                                    textAlign: 'center !important',
                                    borderRadius: '10px',
                                    padding: '5px',
                                  }}
                                  className={
                                    !(newsLetter.frequently === 'Daily' || newsLetter.frequently === 'Monthly')
                                      ? 'color-white'
                                      : ''
                                  }
                                >
                                  {newsLetter.frequently}
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <AppSwitch
                                  checked={newsLetter.status == 1}
                                  onChange={handleSwitchChange(newsLetter.id, newsLetter.status)}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <div className="flex justify-center items-center">
                                  <Tooltip title="Open Newsletters">
                                    <IconButton color="primary" onClick={() => showArtilces(newsLetter.id)}>
                                      <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.60469 10.1233C7.60469 8.8363 8.59831 7.79301 9.82399 7.79301C11.0497 7.79301 12.0433 8.8363 12.0433 10.1233C12.0433 11.4102 11.0497 12.4535 9.82399 12.4535C8.59831 12.4535 7.60469 11.4102 7.60469 10.1233ZM9.05206 10.1233C9.05206 10.5709 9.39766 10.9338 9.82399 10.9338C10.2503 10.9338 10.5959 10.5709 10.5959 10.1233C10.5959 9.67563 10.2503 9.31275 9.82399 9.31275C9.39766 9.31275 9.05206 9.67563 9.05206 10.1233Z"
                                          fill="#085950"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M14.3687 5.83762L18.4021 9.22156C18.6629 9.44076 18.8147 9.77251 18.8147 10.1233C18.8147 10.474 18.6629 10.8058 18.4021 11.025L14.3687 14.4089C11.7104 16.6369 7.93757 16.6369 5.27925 14.4089L1.24592 11.025C0.985118 10.8058 0.833313 10.474 0.833313 10.1233C0.833313 9.77251 0.985118 9.44076 1.24592 9.22156L5.27925 5.83762C7.93757 3.60963 11.7104 3.60963 14.3687 5.83762ZM6.17662 13.2742C8.31139 15.0582 11.3366 15.0582 13.4714 13.2742L17.1573 10.1233L13.4714 7.02301C11.3366 5.239 8.31139 5.239 6.17662 7.02301L2.49065 10.1233L6.17662 13.2742Z"
                                          fill="#085950"
                                        />
                                      </svg>
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Edit Newsletters">
                                    <IconButton color="primary" onClick={() => openEditModal(index)}>
                                      <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.66675 18.3333H7.33341L16.9584 8.70829C17.9709 7.69576 17.9709 6.05414 16.9584 5.04162C15.9459 4.0291 14.3043 4.0291 13.2917 5.04162L3.66675 14.6666V18.3333"
                                          stroke="#085950"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.375 5.95825L16.0417 9.62492"
                                          stroke="#085950"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Newsletters">
                                    <IconButton color="primary" onClick={() => openDeleteModal(index)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 24 24">
                                        <path fill="#f00"
                                              d="M8.47 8.47a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 0 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 1 1-1.06-1.06L10.94 12L8.47 9.53a.75.75 0 0 1 0-1.06" />
                                        <path fill="#f00" fill-rule="evenodd"
                                              d="M7.317 3.769a42.5 42.5 0 0 1 9.366 0c1.827.204 3.302 1.643 3.516 3.48c.37 3.157.37 6.346 0 9.503c-.215 1.837-1.69 3.275-3.516 3.48a42.5 42.5 0 0 1-9.366 0c-1.827-.205-3.302-1.643-3.516-3.48a41 41 0 0 1 0-9.503c.214-1.837 1.69-3.276 3.516-3.48m9.2 1.49a41 41 0 0 0-9.034 0A2.486 2.486 0 0 0 5.29 7.424a39.4 39.4 0 0 0 0 9.154a2.486 2.486 0 0 0 2.193 2.164c2.977.332 6.057.332 9.034 0a2.486 2.486 0 0 0 2.192-2.164a39.4 39.4 0 0 0 0-9.154a2.486 2.486 0 0 0-2.192-2.163"
                                              clip-rule="evenodd" />
                                      </svg>

                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        pt: 3,
                      }}
                      className="pagination-wrapper"
                    >
                      <Pagination variant="outlined" count={count} page={currentPage} onChange={getPaginatedData} />
                    </Box>
                  </>
                ) : (
                  <div className="data-loading">
                    <div className="no-data-cont">
                      <img src={NoDataUrl} className="no-data" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Box>
      <Modal
        className="modal"
        open={createModal}
        onClose={closeCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-width centered-modal">
          <Typography
            className="modal-modal-title d-flex justify-content-between align-items-baseline"
            variant="h6"
            component="h2"
          >
            {messages.CreateNewsletterAgent}
            <IconButton size="small" aria-label="close" color="inherit" ml={1} onClick={closeCreateModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
          {/*<Typography className="modal-modal-title" sx={{ my: 1 }} variant="h6" component="h3">*/}
          {/*  {modalTitle(info.dialogTitle, info.fileName)}*/}
          {/*</Typography>*/}
          <div className="modal-modal-description" sx={{ my: 2 }}>
            <CreateNewsLetter
              sendDataToParent={handleDataFromChild}
              onCreateClick={handleClickCreate}
              locale={locale}
              messages={messages}
            />
          </div>
        </Box>
      </Modal>
      <Modal
        className="modal"
        open={editModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-width centered-modal">
          <Typography
            className="modal-modal-title d-flex justify-content-between align-items-baseline"
            variant="h6"
            component="h2"
          >
            {messages.EditNewsletterAgent}
            <IconButton size="small" aria-label="close" color="inherit" ml={1} onClick={closeEditModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
          <div className="modal-modal-description" sx={{ my: 2 }}>
            <EditNewsLetter
              newsLetterId={newsLetterId}
              onEditClick={handleClickEdit}
              locale={locale}
              messages={messages}
            />
          </div>
        </Box>
      </Modal>
      <Modal
        className="modal"
        open={deleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-width centered-modal">
          <Typography
            className="modal-modal-title d-flex justify-content-between align-items-baseline"
            variant="h6"
            component="h2"
          >
            {messages.DeleteConfirmation}
            <IconButton size="small" aria-label="close" color="inherit" ml={1} onClick={closeDeleteModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
          <div className="flex flex-col gap-4" sx={{ my: 2 }}>
            <Typography className="confirm-delete" variant="h4">
              {messages.AreYouSureYouWantToDeleteIt}
            </Typography>
            <div className="flex gap-4">
              <Button
                variant="contained"
                color="success"
                className={loadingDelete ? 'cursor-not-allowed btn__primary' : 'btn__primary'}
                onClick={deleteNewsLetter}
              >
                {messages.Delete}
              </Button>
              <Button variant="outlined" className="btn-default-outlined" onClick={() => closeDeleteModal()}>
                {messages.Cancel}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" variant="filled" sx={{ width: '100%' }}>
          {messages.DeletedSuccessfully}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={2500} onClose={handleCloseFailed}>
        <Alert
          className="alert-error"
          onClose={handleCloseFailed}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {messages.FailedtoDelete}
        </Alert>
      </Snackbar>
    </>
  );
};
