import React from 'react';
import { Box, Card, CardContent, Typography, Avatar, Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultImage from "../../images/earth.svg"

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1rem',
    maxWidth: 'none',
    padding: '1rem',
    width: '300px',
    height: 'fit-content',
    background: 'rgba(8,89,80,0.75)'
  },
});

interface SourceCardProps {
  title: string;
  domain: string;
  icon_url: string;
  url: string;
  index: number;
}

const SourceCard: React.FC<SourceCardProps> = ({ title, domain, icon_url, url,index }) => {
  const handleCardClick = () => {
    window.open(url, '_blank');
  };

  const handleError = (e) => {
    e.target.onerror = null;
    e.target.src = defaultImage;
  };

  return (
    <CustomTooltip title={title}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 1,
          padding: 1,
          borderRadius: "10px !important",
          cursor: 'pointer',
          transition: "0.3s",
          '&:hover': {
            boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.2)",
            transform: "translateY(-5px)"
          }
        }}
        onClick={handleCardClick}
      >
        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontSize: '12px',marginInline:1,fontWeight:"bold" }}>{index+1}.</Typography>
          <Avatar src={icon_url} onError={handleError} sx={{ marginRight: 2, width: '20px', height: '20px' }} />
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>{domain}</Typography>
        </CardContent>
      </Card>
    </CustomTooltip>
  );
};

interface SourcesComponentProps {
  sources: SourceCardProps[];
}

const SourcesComponent: React.FC<SourcesComponentProps> = ({ sources }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: 2, marginTop: 2, padding: 0 }} className={'resources-card'}>
      {sources.map((source, index) => (
        <SourceCard key={index} title={source.title} domain={source.domain} icon_url={source.icon_url} url={source.url}  index={index}/>
      ))}
    </Box>
  );
};

export default SourcesComponent;
