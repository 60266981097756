import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Link from '@mui/material/Link';
import { ROUTES } from '../../app.constants';
import { useAdminPanelContext } from '../../../shared/components/adminPanel/adminPanelContext';
import wakebLight from '../../../images/AI-Platform-Light-0.png';
import { LoginWrapper } from './login.styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import '@fontsource/inter';
import '@fontsource/karla';
import '@fontsource/cabin';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';


export const Login = ({ locale, messages }) => {
  const intl = useIntl();
  const history = useHistory();
  const { isLoading, user, signIn, errorMessage, showLoginVerify, verifyRegistration, otpErrorMessage, otpSendSuccess, otpLoading, resendCode } = useAdminPanelContext();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login') {
      let appDiv = document.getElementById('app');
      appDiv.style.padding = '0';
      appDiv.style.marginLeft = '0';
      appDiv.style.maxWidth = '100%';
    }

    if (typeof user?.token != 'undefined' && user.token !== '') {
      history.push(ROUTES.search);
    }
  }, [user]);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogIn = () => {
    signIn(email, password);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLogIn();
    }
  };

  // Verify
  const [registerOtp, setRegisterOtp] = React.useState('');
  const handleVerify = () => {
    verifyRegistration({
      email: email,
      email_verification_code: registerOtp
    });
  };
  const handleVerifyKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleVerify()
    }
  };
  const handleResend = (event) => {
    resendCode({
      email: email
    })
  };
  return (
    <LoginWrapper>
      {locale == 'en' ? (
        <Helmet
          title={intl.formatMessage({
            id: 'login',
            defaultMessage: 'Login',
            description: 'Login',
          })}
        />
      ) : (
        <Helmet
          title={intl.formatMessage({
            id: 'login',
            defaultMessage: 'تسجيل الدخول',
            description: 'تسجيل الدخول',
          })}
        />
      )}
      <div className="login-page">
        <video id="background-video" autoPlay loop muted poster="../../../images/login-bg-light.jpg">
          <source src="/login-bg-light.mp4" type="video/mp4" />
        </video>
        <div className="logo-side">
          <div>
            <img src={wakebLight} alt="AIP" className="login-logo light" loading="lazy"  />
          </div>
          <div>
            <Typography variant="h1" className="mb-0">
              {messages.AIP}
            </Typography>
            <Typography variant="h1">{messages.powerOfAI}</Typography>
            <Typography variant="h5">
              {messages.ChatWithTheSmartestAI}
              <br />
              {messages.ExperienceThePowerOfAIWithUs}
            </Typography>
          </div>
          <Typography variant="h6">{messages.WakebAllRightReserved}</Typography>
        </div>
        <div className="login-content">
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {
              !showLoginVerify ? (
                <div>
                  <Typography variant="h5">{messages.LetGetStarted}</Typography>
                  <Typography variant="h6">{messages.LogInItWillEnableYouToGetAllTheFeatures}</Typography>
                  <form onKeyPress={handleKeyPress} autoComplete="off">
                    <FormControl sx={{ mb: 4, width: '100%' }}>
                      <label htmlFor="email" className="mb-1 d-block">
                        {messages.EmailAddress}
                      </label>
                      <OutlinedInput
                        id="email"
                        type="email"
                        value={email}
                        placeholder={messages.EmailAddress}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #085950',
                          },
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 1, width: '100%' }}>
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <label htmlFor="password">{messages.Password}</label>
                      </div>
                      <OutlinedInput
                        id="standard-adornment-password"
                        placeholder={messages.Password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        autoComplete="false"  // Add this line to prevent password saving
                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #085950',
                          },
                        }}
                      />

                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <small>{messages.Min8Character}</small>
                        <Link href={ROUTES.forgetPassword} className="mb-0 mt-1">
                          {messages.ForgetPassword}
                        </Link>
                      </div>
                    </FormControl>
                    {errorMessage.length ? <div className="text-danger">{errorMessage}</div> : ''}
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="success"
                        className={isLoading ? 'cursor-not-allowed' : ''}
                        onClick={handleLogIn}
                      >
                        {messages.SignIn}
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="success"
                        className="outlined"
                        onClick={() => history.push(`/register`)}
                      >
                        {messages.createNewAccount}
                      </Button>
                    </div>
                  </form>
                </div>
              ): (
                <div>
                  <Typography variant="h5">{messages.sendEmailVerificationCode}</Typography>
                  <Typography variant="h6">{messages.EnterEmailVerificationCode}</Typography>
                  <form onKeyPress={handleVerifyKeyPress}>
                    <FormControl sx={{ mb: 2, width: '100%' }}>
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <label htmlFor="password">{messages.verificationCode}</label>
                      </div>
                      <OutlinedInput
                        id="otp"
                        type="text"
                        value={registerOtp}
                        autoComplete='off'
                        placeholder={messages.enterCode}
                        onChange={(e) => setRegisterOtp(e.target.value)}
                      />
                    </FormControl>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div></div>
                      <Link onClick={handleResend} className="mb-0 mt-0 cursor-pointer">
                        {messages.resendCode}
                      </Link>
                    </div>
                    {otpErrorMessage.length ? <div className="text-danger">{otpErrorMessage}</div> : ''}
                    {otpSendSuccess ? <div className="text-info">{messages.codeResendSuccessfully}</div> : ''}
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="success"
                        className={otpLoading ? 'cursor-not-allowed' : ''}
                        onClick={handleVerify}
                      >
                        {messages.verify}
                      </Button>
                    </div>
                  </form>
                </div>
              )
            }
          </Box>
        </div>
      </div>
    </LoginWrapper>
  );
};
