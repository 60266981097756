import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Link from '@mui/material/Link';
import { ROUTES } from '../../app.constants';
import { useAdminPanelContext } from '../../../shared/components/adminPanel/adminPanelContext';
import wakebLight from '../../../images/AI-Platform-Light-0.png';
import { LoginWrapper } from './login.styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import '@fontsource/inter';
import '@fontsource/karla';
import '@fontsource/cabin';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import {Grid} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


export const Register = ({ locale, messages }) => {
  const intl = useIntl();
  const history = useHistory();
  const { registerLoading, user, signUp, verifyRegistration, showOtp,otpErrorMessage, otpLoading, firstNameError,lastNameError,
    passwordError, emailError, resendCode, otpSendSuccess} = useAdminPanelContext();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/register') {
      let appDiv = document.getElementById('app');
      appDiv.style.padding = '0';
      appDiv.style.marginLeft = '0';
      appDiv.style.maxWidth = '100%';
    }

    if (typeof user?.token != 'undefined' && user.token !== '') {
      history.push(ROUTES.search);
    }
  }, [user]);


  const [userPersonalInfo, setUserPersonalInfo] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    _method: 'post',
  });


  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false);
  const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);
  const handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  const handleRegister = () => {
    const data = {
      first_name: userPersonalInfo.first_name,
      last_name: userPersonalInfo.last_name,
      email: userPersonalInfo.email?.split('@')[0] + emailOptionSelected,
      password: userPersonalInfo.password,
      password_confirmation: userPersonalInfo.password_confirmation,
      _method: 'post',
    }
    signUp(data);
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleRegister()
    }
  };

  const [emailOptions, setEmailOptions] = React.useState([
    {value: "@wakeb.tech", label: "@wakeb.tech"},
  ])
  const [emailOptionSelected, setEmailOptionSelected] = React.useState("@wakeb.tech")
  const [emailFrontError, setEmailFrontError] = React.useState("")
  const handleChange = (event) => {
    if(event.target.value){
      setEmailOptionSelected(event.target.value);
    }

  };
  const [registerOtp, setRegisterOtp] = React.useState('');
  const handleVerify = () => {
    verifyRegistration({
      email: userPersonalInfo.email?.split('@')[0] + emailOptionSelected,
      email_verification_code: registerOtp
    });
  };
  const handleVerifyKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleVerify()
    }
  };
  const handleResend = (event) => {
    resendCode({
      email: userPersonalInfo.email?.split('@')[0] + emailOptionSelected,
    })
  };
  return (
    <LoginWrapper>
      {locale == 'en' ? (
        <Helmet
          title={intl.formatMessage({
            id: 'register',
            defaultMessage: 'Register',
            description: 'Register',
          })}
        />
      ) : (
        <Helmet
          title={intl.formatMessage({
            id: 'register',
            defaultMessage: 'انشاء حساب',
            description: 'انشاء حساب',
          })}
        />
      )}
      <div className="login-page">
        <video id="background-video" autoPlay loop muted poster="../../../images/login-bg-light.jpg">
          <source src="/login-bg-light.mp4" type="video/mp4" />
        </video>
        <div className="logo-side">
          <div>
            <img src={wakebLight} alt="AIP" className="login-logo light" loading="lazy"  />
          </div>
          <div>
            <Typography variant="h1" className="mb-0">
              {messages.AIP}
            </Typography>
            <Typography variant="h1">{messages.powerOfAI}</Typography>
            <Typography variant="h5">
              {messages.ChatWithTheSmartestAI}
              <br />
              {messages.ExperienceThePowerOfAIWithUs}
            </Typography>
          </div>
          <Typography variant="h6">{messages.WakebAllRightReserved}</Typography>
        </div>
        <div className="login-content register">
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            {
              !showOtp ?
                <div>
                  <Typography variant="h5">{messages.letsCreateAccount}</Typography>
                  <Typography variant="h6">{messages.LogInItWillEnableYouToGetAllTheFeatures}</Typography>
                  <form onKeyPress={handleKeyPress} autoComplete="off" id="registerForm">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {/*First Name*/}
                        <Grid item xs={12} lg={12}>
                          <FormControl sx={{ mb: 1, width: '100%' }}>
                            <label htmlFor="firstName" className="mb-1 d-block">
                              {messages.FirstName}
                            </label>
                            <OutlinedInput
                              id="firstName"
                              type="text"
                              value={userPersonalInfo.first_name}
                              placeholder={messages.FirstName}
                              onChange={(e) => {
                                setUserPersonalInfo({
                                  ...userPersonalInfo,
                                  first_name: e.target.value,
                                })
                              }}
                              sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #085950',
                                },
                              }}
                            />
                            {firstNameError ? <span className="text-danger mt-1">{firstNameError}</span> : ''}
                          </FormControl>
                        </Grid>
                        {/*Last Name*/}
                        <Grid item xs={12} lg={12}>
                          <FormControl sx={{ mb: 1, width: '100%' }}>
                            <label htmlFor="lastName" className="mb-1 d-block">
                              {messages.LastName}
                            </label>
                            <OutlinedInput
                              id="lastName"
                              type="text"
                              value={userPersonalInfo.last_name}
                              placeholder={messages.last_name}
                              onChange={(e) => {
                                setUserPersonalInfo({
                                  ...userPersonalInfo,
                                  last_name: e.target.value,
                                });
                              }}
                              sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #085950',
                                },
                              }}
                            />
                            {lastNameError ? <span className="text-danger mt-1">{lastNameError}</span> : ''}
                          </FormControl>
                        </Grid>
                        {/*email*/}
                        <Grid item xs={12} lg={12}>
                          <FormControl sx={{ mb: 1, width: '100%' }}>
                            <label htmlFor="email" className="mb-1 d-block">
                              {messages.EmailAddress}
                            </label>
                            <OutlinedInput
                              type="text"
                              value={userPersonalInfo.email}
                              placeholder={messages.EmailAddress}
                              onChange={(e) => {
                                let format = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
                                let value = e.target.value;
                                if (format.test(value)) {
                                  setEmailFrontError("Enter valid Email")
                                } else {
                                  setUserPersonalInfo({ ...userPersonalInfo, email: value});
                                  setEmailFrontError("")
                                }

                              }}
                              sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #085950',
                                },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                 <div id="email-select">
                                   <Select
                                     value={emailOptionSelected}
                                     onChange={handleChange}
                                     MenuProps={{
                                       PaperProps: {
                                         className: 'email-select', // Add a custom class to the dropdown menu
                                       },
                                     }}
                                     sx={{
                                       border: 'none',
                                       '& .MuiSelect-select': {
                                         border: 'none',
                                       },
                                       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                         border: 'none',
                                       },
                                     }}
                                   >
                                     {emailOptions.map((option) => (
                                       <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem>
                                     ))}

                                   </Select>
                                 </div>
                                </InputAdornment>
                              }

                            />
                            {emailError ? <span className="text-danger mt-1">{emailError}</span> : ''}
                            {emailFrontError ? <span className="text-danger mt-1">{emailFrontError}</span> : ''}
                          </FormControl>
                        </Grid>
                        {/*Password*/}
                        <Grid item xs={12} lg={12}>
                          <FormControl sx={{ mb: 1, width: '100%' }}>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <label htmlFor="password">{messages.Password}</label>
                            </div>
                            <OutlinedInput
                              id="standard-adornment-password"
                              placeholder={messages.Password}
                              value={userPersonalInfo.password}
                              onChange={(e) => {
                                setUserPersonalInfo({ ...userPersonalInfo, password: e.target.value });
                              }}
                              type={showPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              autoComplete="false"  // Add this line to prevent password saving
                              sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #085950',
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                        {/*Confirm Password*/}
                        <Grid item xs={12} lg={12}>
                          <FormControl sx={{ mb: 1, width: '100%' }}>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <label htmlFor="password">{messages.ConfirmYourPassword}</label>
                            </div>
                            <OutlinedInput
                              id="standard-adornment-password"
                              placeholder={messages.ConfirmYourPassword}
                              value={userPersonalInfo.password_confirmation}
                              onChange={(e) => {
                                setUserPersonalInfo({ ...userPersonalInfo, password_confirmation: e.target.value });
                              }}
                              type={showPasswordConfirmation ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConfirmation}
                                    onMouseDown={handleMouseDownPasswordConfirmation}
                                    edge="end"
                                  >
                                    {showPasswordConfirmation ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              autoComplete="false"  // Add this line to prevent password saving
                              sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #085950',
                                },
                              }}
                            />
                            {passwordError ? <span className="text-danger mt-1">{passwordError}</span> : ''}
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <small>{messages.Min8Character}</small>
                              <Link href={ROUTES.login} className="mb-0 mt-1">
                                {messages.backToLogin}
                              </Link>
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="success"
                        className={registerLoading ? 'cursor-not-allowed' : ''}
                        onClick={handleRegister}
                      >
                        {messages.createAccount}
                      </Button>
                    </div>
                  </form>
                </div> :(
                  <div>
                    <Typography variant="h5">{messages.sendEmailVerificationCode}</Typography>
                    <Typography variant="h6">{messages.EnterEmailVerificationCode}</Typography>
                    <form onKeyPress={handleVerifyKeyPress} >
                      <FormControl sx={{ mb: 2, width: '100%' }}>
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <label htmlFor="password">{messages.verificationCode}</label>
                        </div>
                        <OutlinedInput
                          id="otp"
                          type="text"
                          value={registerOtp}
                          autoComplete='off'
                          placeholder={messages.enterCode}
                          onChange={(e) => setRegisterOtp(e.target.value)}
                        />
                      </FormControl>
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <div></div>
                        <Link onClick={handleResend} className="mb-0 mt-0 cursor-pointer">
                          {messages.resendCode}
                        </Link>
                      </div>
                      {otpErrorMessage.length ? <div className="text-danger">{otpErrorMessage}</div> : ''}
                      {otpSendSuccess ? <div className="text-info">{messages.codeResendSuccessfully}</div> : ''}
                      <div className="mt-2">
                        <Button
                          variant="contained"
                          color="success"
                          className={otpLoading ? 'cursor-not-allowed' : ''}
                          onClick={handleVerify}
                        >
                          {messages.verify}
                        </Button>
                      </div>
                    </form>
                  </div>
                )

            }
          </Box>
        </div>
      </div>
    </LoginWrapper>
  );
};
