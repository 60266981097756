// @ts-nocheck
import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { ROUTES } from '../../app.constants';
import wakebLight from '../../../images/AI-Platform-Light-0.png';

import { LoginWrapper } from './login.styles';
import OutlinedInput from '@mui/material/OutlinedInput';

import '@fontsource/inter';
import '@fontsource/karla';
import '@fontsource/cabin';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

const SEARCH_URL = process.env.REACT_APP_WEB_API;
export const PasswordReset = ({ locale, messages }) => {
  const intl = useIntl();
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const handleForgetPassword = () => {};

  return (
    <LoginWrapper>
      {locale == 'en' ? (
        <Helmet
          title={intl.formatMessage({
            id: 'reset',
            defaultMessage: 'Account recovery',
            description: 'Account recovery',
          })}
        />
      ) : (
        <Helmet
          title={intl.formatMessage({
            id: 'recover',
            defaultMessage: 'استرجاع الحساب',
            description: 'استرجاع الحساب',
          })}
        />
      )}
      <div className="login-page">
        <video id="background-video" autoPlay loop muted poster="../../../images/login-bg-light.jpg">
          <source src="/login-bg-light.mp4" type="video/mp4" />
        </video>
        <div className="logo-side">
          <div>
            <img src={wakebLight} alt="Wakeb" className="login-logo light" loading="lazy"  />
            {/*<img src={wakebDark} alt="Wakeb" className="login-logo dark" />*/}
          </div>
          <div>
            <Typography variant="h1" className="mb-0">
              {messages.AIP}
            </Typography>
            <Typography variant="h1">{messages.powerOfAI}</Typography>
            <Typography variant="h5">
              {messages.ChatWithTheSmartestAI}
              <br />
              {messages.ExperienceThePowerOfAIWithUs}
            </Typography>
          </div>
          <Typography variant="h6">{messages.WakebAllRightReserved}</Typography>
        </div>
        <div className="login-content">
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}
          >
            <Typography variant="h5">{messages.ChangeYourPassword}</Typography>
            <Typography variant="h6">{messages.EnterYourEmailAddressAndWeWillSendYouARecoveryLink}</Typography>
            <form >
              <FormControl sx={{ mb: 4, width: '100%' }}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <label htmlFor="password">{messages.EmailAddress}</label>
                  <Link href={ROUTES.login} className="mb-0 mt-0">
                    {messages.BackToSignIn}
                  </Link>
                </div>
                <OutlinedInput
                  id="email"
                  type="email"
                  value={email}
                  autoComplete='off'
                  shrink
                  placeholder={messages.EmailAddress}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              {errorMessage.length ? <div className="text-danger">{errorMessage}</div> : ''}

              <div className="mt-2">
                <Button
                  variant="contained"
                  color="success"
                  className={isLoading ? 'cursor-not-allowed' : ''}
                  onClick={handleForgetPassword}
                >
                  {messages.SendRecoveryEmail}
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </div>
    </LoginWrapper>
  );
};
