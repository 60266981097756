import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'explore.loading',
    defaultMessage: 'Fetching data',
  },
  error: {
    id: 'explore.error',
    defaultMessage: 'Sorry, we’ve encountered an error while fetching the data. Please refresh the page and try again!',
  },
  getInTouch: {
    id: 'explore.getInTouch',
    defaultMessage: 'Get in touch',
  },
  chatLLM: {
    id: 'chatLLM',
    defaultMessage: 'GADD LLM',
  },
  chatRAGOnline: {
    id: 'chatRAGOnline',
    defaultMessage: 'GADD Live',
  },
  chatRAGOffline: {
    id: 'chatRAGOffline',
    defaultMessage: 'GADD Local',
  },
  chatFiles: {
    id: 'chatFiles',
    defaultMessage: 'GADD Scanner',
  },
  chatBrief7b: {
    id: 'chatBrief7b',
    defaultMessage: 'GADD Brief 7b',
  },
  chatBrief13b: {
    id: 'chatBrief13b',
    defaultMessage: 'GADD Brief 13b',
  },
});
